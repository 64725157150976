<template>
  <div class="c-main--flex-1">
    <h2 class="c-title c-title--page">{{$t('display.control')}}</h2>
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2-at-small">
        <div class="c-input">
          <label class="c-input__label" for="display">{{$t('display')}}</label>
          <select class="c-input__field c-input__field-select" v-model="displaySelected" id="display">
            <option v-for="display in displays" :key="display.id" :value="display.id">
              {{display.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-small">
        <div class="c-input">
          <label class="c-input__label" for="session">{{$t('session')}}</label>
          <select class="c-input__field c-input__field-select" v-model="sessionSelected" id="session">
            <option v-for="session in sessions" :key="session.id" :value="session.id">
              {{getSessionLabel(session)}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <template v-if="displaySelected">
      <div class="c-tabs c-main">
        <div class="c-tab__tab-bar">
          <a v-for="tab in $options.tabs" :key="tab" v-on:click="setPage(tab)"
             class="c-button c-button--large c-button--ghost c-tabs__tab-link"
             :class="{'is-active': page === tab}">
            {{$t('displayControl.' + tab)}}
          </a>
        </div>
        <div class="c-tab__panel c-main u-margin-top-medium">
          <div class="o-layout" v-if="page === 'presentation'">
            <div class="o-layout__item u-1-of-1">
              <h3 class="c-title c-title--page-section">{{$t('display.presentation')}}</h3>
            </div>
            <div class="o-layout__item u-1-of-2-at-small u-margin-bottom-large">
              <template v-if="discipline.individual && memberParticipations.length">
                <div class="c-input">
                  <label class="c-input__label" for="memberParticipation">{{$t('participant')}}</label>
                  <select class="c-input__field c-input__field-select" v-model="memberParticipationSelected"
                          name="memberParticipation" id="memberParticipation">
                    <option v-for="part in memberParticipations" :key="part.id" :value="part.id">
                      {{getPartName(part)}}
                    </option>
                  </select>
                </div>
                <div class="c-button c-button--primary c-button--large"
                     v-on:click="showParticipation(memberParticipationSelected)">
                  {{$t('display.show')}}
                </div>
              </template>
              <template v-if="discipline.group && groupParticipations.length">
                <div class="c-input">
                  <label class="c-input__label" for="groupParticipation">{{$t('group')}}</label>
                  <select class="c-input__field c-input__field-select" v-model="groupParticipationSelected"
                          name="groupParticipation" id="groupParticipation">
                    <option v-for="part in groupParticipations" :key="part.id" :value="part.id">
                      {{getPartName(part)}}
                    </option>
                  </select>
                </div>
                <div class="c-button c-button--primary c-button--large"
                     v-on:click="showParticipation(groupParticipationSelected)">
                  {{$t('display.show')}}
                </div>
              </template>
              <template v-if="discipline.team && teamParticipations.length">
                <div class="c-input u-margin-top-small">
                  <label class="c-input__label" for="teamParticipation">{{$t('team')}}</label>
                  <select class="c-input__field c-input__field-select" v-model="teamParticipationSelected"
                          name="teamParticipation" id="teamParticipation">
                    <option v-for="part in teamParticipations" :key="part.id" :value="part.id">
                      {{getPartName(part)}}
                    </option>
                  </select>
                </div>
                <div class="c-button c-button--primary c-button--large"
                     v-on:click="showParticipation(teamParticipationSelected)">
                  {{$t('display.show')}}
                </div>
              </template>
            </div>
            <div class="o-layout__item u-1-of-2-at-small u-margin-bottom-large">
              <div class="c-input">
                <label class="c-input__label" for="memberParticipation">{{$t('panel')}}</label>
                <select class="c-input__field c-input__field-select" v-model="panelSelected"
                        name="memberParticipation" id="panel">
                  <option v-for="panel in panels" :key="panel.id" :value="panel.id">
                    {{panel.name}}
                  </option>
                </select>
              </div>
              <div class="c-input">
                <label class="c-input__label" for="memberParticipation">{{$t('rotation')}}</label>
                <select class="c-input__field c-input__field-select" v-model="rotationSelected"
                        name="rotation" id="rotation">
                  <option v-for="(rotation, r) in rotations" :key="r" :value="r">
                    {{rotation}}
                  </option>
                </select>
              </div>
              <div class="c-button c-button--primary c-button--large"
                   v-on:click="showBlock()">
                {{$t('display.show')}}
              </div>
            </div>
          </div>
          <div class="o-layout" v-if="page === 'competition'">
            <div class="o-layout__item u-margin-bottom-large">
              <h3 class="c-title c-title--page-section">{{$t('exercises')}}</h3>
              <div class="c-input">
                <label class="c-input__label" for="participation">{{$t('participation')}}</label>
                <select class="c-input__field c-input__field-select" v-model="participationSelected"
                        v-on:change="selectParticipation()" name="participation" id="participation">
                  <option v-for="part in participations" :key="part.id" :value="part.id">
                    {{getPartName(part)}}
                  </option>
                </select>
              </div>
              <table v-if="participationSelected" ref="ranking" class="c-table js-table">
                <thead>
                <tr>
                  <th v-for="exercise in exercises" :key="exercise.exerciseTypeId+'-'+ exercise.pass +'label'"
                    class="c-exercise__titles u-100px u-padding-right-20px">
                    {{$t('exercise.type.' + exercise.exerciseTypeId)}}
                    <span v-if="exercise.passes > 1">
                      {{ exercise.pass }}
                    </span>
                  </th>
                  <th class="c-table__empty-last-col"></th>
                </tr>
                </thead>
                <tbody>
                <tr class="js-row">
                  <th v-for="exercise in exercises" :key="exercise.exerciseTypeId+'-'+exercise.pass+'ann'">
                    <div class="c-button c-button--primary c-button--small"
                         v-on:click="showExercise(exercise.id, exercise.pass+1, 'announce')">
                      {{$t('displayControl.announce')}}
                    </div>
                  </th>
                  <th class="c-table__empty-last-col"></th>
                </tr>
                <tr>
                  <exerciseResult v-for="exercise in exercises" :key="exercise.exerciseTypeId + '-' + exercise.pass + 'result'"
                                  rankingClass="u-100px" :tiny="false"
                                  :exercise="exercise" :pass="exercise.pass" />
                  <td class="c-table__empty-last-col"></td>
                </tr>
                <tr>
                  <th v-for="exercise in exercises" :key="exercise.exerciseTypeId+'-'+ exercise.pass+'score'">
                    <div class="c-button c-button--primary c-button--small"
                         :class="{'c-button--disabled': exercise.status !== 'finished'}"
                         v-on:click="showExercise(exercise.id, exercise.pass+1, 'score')">
                      {{$t('display.show')}}
                    </div>
                  </th>
                  <th class="c-table__empty-last-col"></th>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="o-layout__item u-1-of-2-at-small u-margin-bottom-large">
              <h3 class="c-title c-title--page-section">{{$t('ranking')}}</h3>
              <div class="c-input">
                <label class="c-input__label" for="categoryR">{{$t('category')}}</label>
                <select class="c-input__field c-input__field-select" v-model="categorySelected" id="categoryR"
                        v-on:change="selectCategory()">
                  <option v-for="category in categories" :key="category.id" :value="category.id">
                    {{category.name}}
                  </option>
                </select>
              </div>
              <div class="c-input">
                <label class="c-input__label" for="rankingR">{{$t('ranking')}}</label>
                <select class="c-input__field c-input__field-select" v-model="rankingSelected" id="rankingR">
                  <option v-for="ranking in rankings" :key="ranking.id" :value="ranking.id">
                    {{getRankingTitle(ranking)}}
                  </option>
                </select>
              </div>
              <div class="c-button c-button--primary c-button--large" v-on:click="showRanking()">
                {{$t('display.show')}}
              </div>
            </div>
            <div v-if="discipline.rotationType === 'rotation'"
                 class="o-layout__item u-1-of-2-at-small u-margin-bottom-large">
              <h3 class="c-title c-title--page-section">{{$t('display.vault')}}</h3>
              <div class="c-input">
                <label class="c-input__label" for="vault.code">{{$t('code')}}</label>
                <input v-model="vault.code" class="c-input__field" type="text" id="vault.code" />
              </div>
              <div class="c-input">
                <label class="c-input__label" for="vault.value">{{$t('value')}}</label>
                <input v-model="vault.value" class="c-input__field" type="text" id="vault.value" />
              </div>
              <div class="c-button c-button--primary c-button--large" v-on:click="showVault()">
                {{$t('display.show')}}
              </div>
            </div>
          </div>
          <div class="o-layout" v-if="page === 'ceremony'">
            <div class="o-layout__item u-1-of-2-at-small u-margin-bottom-large">
              <div class="c-input">
                <label class="c-input__label" for="category">{{$t('category')}}</label>
                <select class="c-input__field c-input__field-select" v-model="categorySelected" id="category"
                        v-on:change="selectCategory()">
                  <option v-for="category in categories" :key="category.id" :value="category.id">
                    {{category.name}}
                  </option>
                </select>
              </div>
              <div class="c-input">
                <label class="c-input__label" for="ranking">{{$t('ranking')}}</label>
                <select class="c-input__field c-input__field-select" v-model="rankingSelected" id="ranking">
                  <option v-for="ranking in rankings" :key="ranking.id" :value="ranking.id">
                    {{getRankingTitle(ranking)}}
                  </option>
                </select>
              </div>
              <div class="c-button c-button--primary c-button--large" v-on:click="showCeremony('medalWinners')">
                {{$t('display.show.winners')}}
              </div>
              <div class="c-button c-button--primary c-button--large" v-on:click="showCeremony('flags')">
                {{$t('display.show.flags')}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
        <div class="o-layout__item u-1-of-2-at-tiny">
        </div>

        <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
          <button class="c-button c-button--primary c-button--large" v-on:click="sendPage('default', {})">
            {{$t('display.default')}}
          </button>
          <button class="c-button c-button--primary c-button--large" v-on:click="next()">
            {{$t('next')}} >>
          </button>
        </div>

      </footer>
    </template>
    <div v-else>
      <h3 class="c-title c-title--page-section">{{ $t('displayControl.dummy') }}</h3>
    </div>
  </div>
</template>

<script>
  import config from 'client/config';

  import categoryLib from 'client/lib/category';
  import exerciseLib from 'client/lib/exercise';
  import blockLib from 'client/lib/block';
  import participantLib from 'client/lib/participant';
  import rankingLib from 'client/lib/ranking';
  import sessionLib from 'client/lib/session';

  import filter from 'lodash/filter';
  import find from 'lodash/find';
  import includes from 'lodash/includes';
  import map from 'lodash/map';
  import sortBy from 'lodash/sortBy';

  export default {
    name: "admin-display-control",
    tabs: ['competition', 'presentation', 'ceremony'],
    components: {
      'exerciseResult': require('../../../components/results/exerciseResult.vue').default,
    },
    data: function() {
      return {
        displaySelected: null,
        sessionSelected: null,
        participationSelected: null,
        panelSelected: null,
        rotationSelected: null,
        participationData: {},
        memberParticipationSelected: null,
        groupParticipationSelected: null,
        teamParticipationSelected: null,
        categorySelected: null,
        rankingSelected: null,
        rankings: [],
        vault: {
          code: null,
          value: null,
        },
        page: 'competition',
        exercises: [],
        dummyRankingItem: {},
      };
    },
    computed: {
      displays: function () {
        return this.$store.state.displays.items;
      },
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      participations: function() {
        const session = this.getSelectedSession();
        if (! session) { return []; }

        return sortBy(sessionLib.getParticipations(session), 'bib');
      },
      memberParticipations: function() {
        const session = this.getSelectedSession();
        if (! session) { return []; }

        return sortBy(
            filter(sessionLib.getParticipations(session), item => item.participantType === 'clubMember'), 'bib');
      },
      groupParticipations: function() {
        const session = this.getSelectedSession();
        if (! session) { return []; }

        return sortBy(
            filter(sessionLib.getParticipations(session), item => item.participantType === 'group'), 'bib');
      },
      teamParticipations: function() {
        const session = this.getSelectedSession();
        if (! session) { return []; }

        return sortBy(sessionLib.getTeamParticipations(session), 'bib');
      },
      categories: function() {
        const session = this.getSelectedSession();
        if (! session) { return []; }

        const categoryIds = map(session.categories, item => item.categoryId);
        console.log(categoryIds);

        return sortBy(filter(this.$store.state.categories.items, item => {
          return includes(categoryIds, item.id);
        }), 'index');
      },
      panels: function() {
        const session = this.getSelectedSession();
        if (! session) { return []; }

        return sortBy(filter(this.$store.state.panels.items, item => {
          return item.sessionId === session.id
        }), ['set', 'index']);
      },
      rotations: function() {
        const session = this.getSelectedSession();
        if (! session) { return []; }

        return Array(session.rotations).fill().map((r, r_i) => r_i + 1)
      },
      rounds: function() {
        return this.$store.state.eventDiscipline.rounds;
      },
      sessions: function() {
        return sortBy(this.$store.state.sessions.items, 'index');
      },
    },
    watch: {
      sessionSelected: function() {
        if (this.sessionSelected) {
          this.$store.dispatch('panels.getBySession', {sessionId: this.sessionSelected})
        }
      },
    },
    methods: {
      setPage: function(page) {
        this.page = page;
      },
      showParticipation: function(participationId) {
        if (! this.displaySelected || ! participationId) {
          return;
        }

        let data = {
          participationId: participationId,
        };

        this.sendPage('blockPresentation', data);
      },
      showBlock: function() {
        if (! this.displaySelected || ! this.panelSelected || this.rotationSelected === undefined) {
          return;
        }

        const panel = this.panels.find(p => p.id === this.panelSelected)
        const order = blockLib.getPanelOrder(panel, this.rotationSelected)
        console.log(order)
        const data = {
          title: 'Final ' + this.$t('exercise.type.' + panel.exerciseTypeId),
          eventDisciplineId: panel.eventDisciplineId,
          participations: order[0].participations.map(o => o.participationId)
        }
        console.log(data)
        this.sendPage('blockPresentation', data);
      },
      showExercise: function(exerciseId, pass, type) {
        let data = {
          exerciseId,
          pass,
        }

        this.sendPage(type, data)
      },
      showRanking: function() {
        let data = {
          rankingId: this.rankingSelected,
        };

        this.sendPage('ranking', data);
      },
      showVault: function() {
        if (! this.vault.code || ! this.vault.value || ! this.displaySelected) {
          return;
        }

        console.log('try send');
        this.sendPage('announcement', {title: this.vault.code, text: this.vault.value});
      },
      showCeremony: function(type) {
        if (! this.rankingSelected || ! this.displaySelected) {
          return;
        }

        const data = {
          rankingId: this.rankingSelected,
        };
        this.sendPage(type, data);
      },
      getSessionLabel: function(session) {
        return sessionLib.getSessionLabel(session);
      },
      getPartName: function(participation) {
        const name = participantLib.getName(participation);
        const category = find(this.categories, item => item.id === participation.categoryId);
        return participation.bib + ' ' + name + ' (' + category.name + ')';
      },
      getParticipantNames: function(participationId) {
        const participation = participantLib.getParticipation(participationId);
        return participantLib.getParticipantNames(participation)
      },
      selectParticipation: function() {
        const participation = find(this.participations, item => item.id === this.participationSelected);
        const session = this.getSelectedSession();
        const exerciseTypes = categoryLib.getFormatExerciseTypes(participation.categoryId, session.roundId);
        console.log(exerciseTypes);

        const exercises = []
        exerciseTypes.forEach(exerciseTypeId => {
          const exercise = exerciseLib.getExercise(participation.id, session.roundId, exerciseTypeId);
          if (exercise) {
            const config = exerciseLib.configuration(exercise)
            const passes = config.consolidatePasses ? 1 : exercise.config.passes
            for (let pass = 0; pass < passes; pass++) {
              let exercisePass = {}
              if (exercise.summary) {
                exercisePass = exercise.summary.passes[pass]
              }
              const item = {
                exerciseTypeId,
                id: exercise.id,
                pass,
                passes,
                status: exercise.status,
                summary: {
                  ...exercisePass
                }
              }
              exercises.push(item)
            }
          }
          else {
            exercises.push({
              exerciseTypeId,
            })
          }
        })
        this.exercises = exercises

        this.dummyRankingItem = {
          participationId: this.participationSelected,
        };
      },
      selectCategory: function() {
        this.rankingSelected = null;
        this.rankings = rankingLib.sortRankings(filter(
          this.$store.state.rankings.items, item => item.categoryId === this.categorySelected))
      },
      getSelectedSession: function() {
        if (this.sessionSelected) {
          return find(this.sessions, item => item.id === this.sessionSelected);
        }
        return null;
      },
      getSelectedCategory: function() {
        if (this.categorySelected) {
          return find(this.categories, item => item.id === this.categorySelected);
        }
        return null;
      },
      getRankingTitle: function(ranking) {
        const category = this.getSelectedCategory();
        const round = find(this.rounds, item => ranking.roundId === item.id);
        return rankingLib.getTitle(ranking, category, round);
      },
      sendPage: function(type, data) {
        this.$http.post(config.root + '/addDisplayTask', {
          displayId: this.displaySelected,
          type: type,
          data: data,
          timing: {
            min: 1,
            max: 300,
          }
        }).then(() => {
          console.log('sent');
        });
      },
      next: function() {
        this.$http.post(config.root + '/displayNext', {
          displayId: this.displaySelected,
        }).then(() => {
          console.log('next sent');
        });
      },
    }
  }
</script>

<style scoped>

</style>
